import React, { useContext } from "react";
import Button from "components/Button";
import Text from "components/Text";
import { AuthDataContext } from "providers/Auth/provider";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { Menu, MenuItem } from "@material-ui/core";
import { ConfigContext } from "providers/Config/provider";
import { setCookie } from "utils/cookie";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components";
import Container, { Avatar } from "./styles";

const Header = () => {
  const history = useHistory();
  const config = useContext(ConfigContext);
  const auth = useContext(AuthDataContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (closeSession = false) => {
    setAnchorEl(null);
    if (closeSession) {
      setCookie('auth', null, -1);
      history.push('/auth/login');
    }
  };

  return (
    <Container sideBarStatic={config.sideBarStatic}>
      <Text fontFamily="secondary" color="gray700" fontWeight="600">
        Taxi Will
      </Text>
      <Button
        aria-controls="simple-menu"
        color="primary"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Text variant="p" pr="10px">
          {auth.email}
        </Text>
        <Avatar>
          <PermIdentityOutlinedIcon fontSize="small" />
        </Avatar>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(false)}
      >
        <MenuItem onClick={() => handleClose(true)}>Cerrar Sesión</MenuItem>
      </Menu>
    </Container>
  );
};

export default withTheme(Header);
