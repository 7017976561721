/* eslint-disable react/prop-types */
import React from 'react';
import Globals from "utils/globals"
import { Grid, Tooltip } from "@material-ui/core";
import { Point} from "./styles";

const columns = [
  {
    Header: "",
    id: "name",
    isVisible: false,
    hideHeader: false,
    columns: [
      {
        Header: "Tipo",
        Cell: ({ row }) => (row.original.driverInfo.documentType === '01' ? 'DNI' : 'CE'),
      },
      {
        Header: "Documento",
        Cell: ({ row }) => (row.original.driverInfo.document),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      /*{
        Header: "Tipo Servicio",
        Cell: ({ row }) => (
          row.original.driverInfo.typeService !== null ? Globals.typeService.find((e) => e.value === row.original.driverInfo.typeService).label : ''
        ),
      },*/
      {
        Header: "Tipo Servicio",
        accessor: 'driverInfo.typeService',
        Filter: 'select',
        filterLabel: 'Tipo de Servicio',
        filterOptions: Globals.typeService,
        getHeaderProps: () => ({
          align: 'center',
        }),
        Cell: ({ row }) => (
          //row.original.driverInfo.typeService !== null ? Globals.typeService.find((e) => e.value === row.original.driverInfo.typeService).label : ''
          Globals.typeService.find((e) => e.value == row.original.driverInfo.typeService).label
          /*<Grid container justify="center">
            <Tooltip title={row.original.statusService ? "En servicio" : "Fuera de servicio"} aria-label="status">
              <Point
                fontSize="small"
                color={row.original.statusService ? '#00a000' : '#ea1b1e'}
              />
            </Tooltip>
          </Grid>*/
        ),
      },
      {
        Header: "Marca",
        Cell: ({ row }) => (row.original.driverInfo.marc),
      },
      {
        Header: "Modelo",
        Cell: ({ row }) => (row.original.driverInfo.model),
      },
      {
        Header: "Placa",
        Cell: ({ row }) => (row.original.driverInfo.plate),
      },
      {
        Header: "En Servicio",
        accessor: 'statusService',
        Filter: 'select',
        filterLabel: 'Estado de servicio',
        filterOptions: [
          {value: 'false', label: 'Fuera de servicio'},
          {value: 'true', label: 'En servicio'},
        ],
        getHeaderProps: () => ({
          align: 'center',
        }),
        Cell: ({ row }) => (
          <Grid container justify="center">
            <Tooltip title={row.original.statusService ? "En servicio" : "Fuera de servicio"} aria-label="status">
              <Point
                fontSize="small"
                color={row.original.statusService ? '#00a000' : '#ea1b1e'}
              />
            </Tooltip>
          </Grid>
        ),
      },
    ],
  },
];

export default columns;
