import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import {
  Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment,
  OutlinedInput, FormHelperText, CircularProgress,
} from "@material-ui/core";
import { SnackbarDispatchContext } from "providers/Snackbar/provider";
import { SNACKBAR_SHOW } from "providers/Snackbar/actions";
import {
  PermIdentity, Visibility, VisibilityOff, LockOutlined,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import useFetch from "services/useFetch";
import { setCookie } from "utils/cookie";
import { AUTH_LOGIN } from "providers/Auth/actions";
import { AuthDispatchContext } from "providers/Auth/provider";
import Users from '../../../public/data/users.json'
import Container, { GridForm, GridImage, Overlay, Logo, Text, FormControl } from "./styles";
import validation from "./validation";

const Auth = ({ history }) => {
  const { fetch, loading } = useFetch({});
  const dispatchAuthData = useContext(AuthDispatchContext);
  const dispatch = useContext(SnackbarDispatchContext);
  const { register, handleSubmit, control, errors } = useForm({ resolver: validation });

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    const config = {
      method: 'post',
      url: process.env.RAZZLE_API_AUTH,
    }

    if (!Users.some((e) => e.email === data.email)) {
      dispatch({
        type: SNACKBAR_SHOW,
        payload: {
          severity: "error",
          message: "Usuario no autorizado",
        },
      });
      return;
    }

    const dataToSend = {
      ...data,
      returnSecureToken: true,
    }

    const response = await fetch(dataToSend, config);

    if (response.status === 200) {
      const dataUser = {
        access_token: response.data.idToken,
        email: response.data.email,
        localId: response.data.localId,
        refresh_token: response.data.refreshToken,
        expires_in: response.data.expiresIn,
        displayName: response.data.displayName,
      };
      setCookie("auth", JSON.stringify(dataUser));
      dispatchAuthData({
        type: AUTH_LOGIN,
        payload: dataUser
      })
      setTimeout(() => {
        history.push("/admin/conductores");
      }, 0);
    }
  };

  return (
    <>
      {typeof document !== 'undefined' && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Inicio de sesión</title>
          </Helmet>
          <Container>
            <GridImage>
              <Overlay />
            </GridImage>
            <GridForm>
              <Grid container justify="center">
                <Grid item xs={8} container alignItems="center" direction="column">
                  <Logo
                    src="/images/logoWill.png"
                    alt="Logo"
                  />
                  <Text styles="welcome" fontFamily="secondary">
                    Bienvenido!
                  </Text>
                  <Text styles="welcomeMsg">Ingresa tus datos para continuar.</Text>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        as={OutlinedInput}
                        id="input-with-icon-adornment"
                        inputRef={register}
                        control={control}
                        defaultValue=""
                        name="email"
                        placeholder="Tu correo electrónico"
                        error={errors.email}
                        startAdornment={(
                          <InputAdornment position="start">
                            <PermIdentity />
                          </InputAdornment>
                        )}
                      />
                      {errors.email && (
                        <FormHelperText error>
                          {errors.email.message}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        inputRef={register}
                        control={control}
                        name="password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        placeholder="Contraseña"
                        error={errors.password}
                        onChange={handleChange("password")}
                        startAdornment={(
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        )}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                      {errors.password && (
                        <FormHelperText error>
                          {errors.password.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          onChange={handleChange}
                          name="checkedB"
                          color="primary"
                        />
                      )}
                      label="Recordar contraseña"
                    />
                    <Grid container justify="center">
                      <Grid item xs={5}>
                        <Button
                          color="secondary"
                          variant="contained"
                          type="submit"
                          fullWidth
                        >
                          {" "}
                          {loading ? <CircularProgress size={24} /> : "Ingresar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </GridForm>
          </Container>
        </>
      )}
    </>
  );
};

Auth.propTypes = {
  // theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withTheme(Auth);
