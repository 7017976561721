import { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { setCookie } from "utils/cookie";
import { SnackbarDispatchContext } from "providers/Snackbar/provider";
import { SNACKBAR_SHOW } from "providers/Snackbar/actions";
import { useHistory } from "react-router-dom";

const useFetch = ({ loading: initialLoading, uri, ...config }) => {
  const [loading, setLoading] = useState(initialLoading);
  const dispatch = useContext(SnackbarDispatchContext);
  const history = useHistory();

  const fetch = async (data = {}, configAux = {}) => {
    setLoading(true);
    try {
      const response = await axios({
        ...config,
        ...(config.method !== "get" ? { data } : { params: data }),
        url: uri,
        ...configAux,
      });

      setTimeout(() => {
        setLoading(false);
      }, 100);
      return response;
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: SNACKBAR_SHOW,
          payload: {
            message:
              // error.response.data.message || error.response.data.error.message ||
              "Error comunicación con el servidor",
            severity: "error",
          },
        });
        setLoading(false);
      }, 100);
      if (error.response.status === 401) {
        setCookie("auth", null);
        history.push('/auth/login');
      }
      return error.response;
    }
  };

  return {
    loading,
    fetch,
  };
};

useFetch.propTypes = {
  uri: PropTypes.string,
  loading: PropTypes.bool,
  method: PropTypes.string,
};

useFetch.defaultProps = {
  uri: "/",
  loading: false,
  method: "get",
};

export default useFetch;
