/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useContext } from "react";
import {
  Button, CircularProgress, Divider, FilledInput,
  FormHelperText, Paper
} from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Text from "components/Text";
import useFetch from "services/useFetch";
import { SnackbarDispatchContext } from "providers/Snackbar/provider";
import { SNACKBAR_SHOW } from "providers/Snackbar/actions";
import imageCompression from 'browser-image-compression';
import LazyImage from "components/LazyImage";
import validation from "./validation";
import { Grid, Avatar } from "./styles";

const ModalAddImage = ({ data, reload, onClose }) => {
  const { register, handleSubmit, errors, watch, reset } = useForm({ resolver: validation });
  const file = watch('image');
  const dispatch = useContext(SnackbarDispatchContext);
  const { fetch: submit, loading: loadingSubmit } = useFetch({});

  const onSubmit = async () => {
    if (!loadingSubmit) {
      const config = {
        method: "post",
        url: `/api/users/image/${data.id}`,
        contentType: "multipart/form-data",
      };

      const dataSend = new FormData();

      const compressedImg = await imageCompression(file[0], {
        maxSizeMB: 0.6,
        maxWidthOrHeight: 500,
        useWebWorker: true
      });

      dataSend.append('file', compressedImg); 

      const response = await submit(dataSend, config);

      if (response.status === 201 || response.status === 200) {
        reload();
        dispatch({
          type: SNACKBAR_SHOW,
          payload: {
            severity: "success",
            message: "Imagen agregada exitosamente",
          },
        });
        reset();
        onClose();
      }
    }
  };

  const handleUrlFile = (fileSelected) => (
    fileSelected && fileSelected[0] ? URL.createObjectURL(fileSelected[0]) : null
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper>
        <Grid container>
          <Grid item xs={12} styles="header">
            <Text variant="h3">Agregar Imagen</Text>
          </Grid>
        </Grid>
        <Grid container styles="container" spacing={2}>
          <Grid item xs={12}>
            <Grid container item xs={12} justify="center">
              {data && (
                <label htmlFor="image">
                  <Avatar src={handleUrlFile(file)}>
                    <LazyImage
                      id={data.id}
                      alt="profile"
                      width="300"
                      height="300"
                    />
                  </Avatar>
                </label>
              )}
            </Grid>
            <FilledInput
              type="file"
              id="image"
              accept="image/*"
              name="image"
              inputRef={register}
              style={{ display: 'none' }}
            />
            {errors.image && (
            <Grid item xs={12}>
              <FormHelperText error>
                {errors.image.message}
              </FormHelperText>
            </Grid>
              )}
          </Grid>
        </Grid>
        <Divider />
        <Grid container styles="container" justify="flex-end" spacing={2}>
          <Grid item xs={2}>
            <Button size="large" fullWidth onClick={() => { onClose(); reset(); }}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              {loadingSubmit ? <CircularProgress size={24} /> : "Aceptar"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default withRouter(ModalAddImage);
