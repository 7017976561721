/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from "@material-ui/core";
import LazyImage from "components/LazyImage";
import { toCapitalize } from "utils/functions";
import { Avatar, Name } from "./styles";

const columns = [
  {
    Header: "",
    id: "name",
    isVisible: false,
    hideHeader: false,
    columns: [
      {
        Header: "Nombre",
        Cell: ({ row }) => (
          <Grid container alignItems="center">
            <Avatar>
              <LazyImage
                alt="img"
                height="50px"
                width="50px"
                effect="blur"
                src={row.original.image}
              />
            </Avatar>
            <Name>{toCapitalize(row.original.name)}</Name>
          </Grid>
        ),
      },
      {
        Header: "Teléfono",
        accessor: "phone"
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
  },
];

export default columns;
