import styled from "styled-components";
import css from "@styled-system/css";

export const Content = styled.div`
  ${(props) =>
    css({
      position: "relative",
      margin: 0,
      minHeight: "calc(100vh - 64px)",
      width: "100%",
      marginTop: "64px",
      paddingBottom: "25px",
      paddingLeft: [
        "0",
        `${props.sideBarStatic ? "280px" : "70px"}`,
      ],
    })}
`;

export default styled.div`
  ${(props) =>
    css({
      display: "flex",
      position: "relative",
      background: props.theme.colors.bodyBg,
    })}

  ::before {
    content: "";
    display: inline-block;
    display: block;
    position: absolute;
    width: 100%;
    height: 300px;
    background: ${(props) => props.theme.colors.secondary};
    top: 0;
    left: 0;
  }
`;

export const ContainerLoading = styled.div`
  ${() => css({
    position: "fixed",
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;
