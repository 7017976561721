import styled from "styled-components";
import {
  Container as ContainerTemplate,
} from "@material-ui/core";
import css from "@styled-system/css";

export const Container = styled.div`
  ${(props) =>
    css({
      left: 0,
      width: "100%",
      top: "0",
      paddingLeft: 0,
      margin: ["32px 0"],
      background: props.theme.colors.secondary,
    })}
`;
export const ContainerBreadcrumb = styled(ContainerTemplate)`
  ${css({
    paddingLeft: [0],
    paddingRight: [0],
  })}
`;

export const Breadcrumb = styled.ul`
  ${css({
    listStyle: "none",
    display: "flex",
  })}
`;

export const BreadcrumbItem = styled.li`
  ${(props) =>
    css({
      display: 'flex',
      marginRight: ["0.5em"],
      a: {
        color: "black",
        marginRight: ["0.5em"],
      },
      span: {
        color: props.theme.colors.gray,
      }
    })}
`;

export const Title = styled.h3`
  ${css({
    fontSize: ["1.7em"],
    fontWeight: 600,
    color: "white",
  })}
`;
