/* eslint-disable prettier/prettier */
import PropTypes from "prop-types";
import NotFound from "shared/NotFound";
import Drivers from "./Drivers/DriversList";
import Clients from "./Clients/ClientList";
import CreateEditDriver from './Drivers/CreateEditDriver';
import Services from "./Services/ServicesList";

const routes = [
  {
    path: "/admin/conductores",
    component: Drivers,
    exact: true
  },
  {
    path: "/admin/conductores/registrar",
    component: CreateEditDriver,
    exact: true
  },
  {
    path: "/admin/conductores/editar/:id",
    component: CreateEditDriver,
    exact: true
  },
  {
    path: "/admin/clientes",
    component: Clients,
    exact: true
  },
  {
    path: "/admin/carreras",
    component: Services,
    exact: true
  },
  {
    path: "**",
    component: NotFound
  },
];

routes.propTypes = {
  path: PropTypes.string,
  component: PropTypes.element,
  exact: PropTypes.bool,
  protected: PropTypes.bool,
};

export default routes;
