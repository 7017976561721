/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import useFetch from "services/useFetch";
import Table from "components/Table";
import NavigationSection from "shared/NavigationSection";
import { Helmet } from "react-helmet";
import { Paper } from "@material-ui/core";
import columns from "./columns";

const Drivers = () => {
  const { fetch, loading } = useFetch({ uri: "/api/clients" });
  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await fetch({});
    if (response.status === 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lista de Conductores</title>
      </Helmet>
      <Paper>
        <Table
          checkbox={false}
          columns={columns}
          data={data}
          loading={loading}
        />
      </Paper>
    </>
  );
};

export default NavigationSection({
  title: "Clientes",
  pages: [],
})(Drivers);
