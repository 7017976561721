import PropTypes from "prop-types";
import Auth from "modules/Auth";
import Admin from "modules/Admin";

const routes = [
  {
    path: "/admin",
    component: Admin
  },
  { 
    path: "/auth",
    component: Auth
  },
];

routes.propTypes = {
  path: PropTypes.string,
  component: PropTypes.element,
  exact: PropTypes.bool,
  protected: PropTypes.bool,
};

export default routes;
