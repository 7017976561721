/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import Header from "shared/Header";
import { Container as ContainerContent } from "@material-ui/core";
import Sidebar from "shared/Sidebar";
import { ConfigContext } from "providers/Config/provider";
import Container, { Content } from "./styles";

const Layout = ({ children }) => {
  const config = useContext(ConfigContext);

  return (
    <>
      {typeof document !== 'undefined' && (
        <Container>
          <Sidebar />
          <Content sideBarStatic={config.sideBarStatic}>
            <Header />
            <ContainerContent>{children}</ContainerContent>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Layout;
