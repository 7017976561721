/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import * as yup from "yup";
import useYupValidationResolver, { setLocaleYup } from "utils/yup";

setLocaleYup();

const validationSchema = yup.object({
  name: yup.string().required(),
  documentType: yup.string().required(),
  document: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  typeService: yup.string().required(),
  plate: yup.string().required(),
  marc: yup.string().required(),
  model: yup.string().required(),
  fabrishYear: yup.string().required(),
});

const validation = useYupValidationResolver(validationSchema);

export default validation;
