/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import useFetch from "services/useFetch";
import Table from "components/Table";
import NavigationSection from "shared/NavigationSection";
import { Helmet } from "react-helmet";
import { Paper , IconButton, Tooltip, Grid } from "@material-ui/core";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Dialog from "components/Dialog";
import { toCapitalize } from "utils/functions";
import columns from "./columns";
import ModalDetail from "./components/ModalDetail";

const Services = () => {
  const [driversOptions, setDriversOption] = useState([]);
  const [tableColumns, setTableColumns] = useState(columns);
  const { fetch, loading } = useFetch({ uri: "/api/rides/services" });
  const { fetch: fetchDrivers, loading: loadingDrivers } = useFetch({ uri: "/api/drivers" });
  const [data, setData] = useState([]);
  const [modalDetail, setModalDetail] = useState({ isOpen: false, data: {} })

  const getData = async () => {
    const respondeDrivers = await fetchDrivers({});
    const drivers = respondeDrivers.data;
    const response = await fetch({});
    if (response.status === 200) {
      setDriversOption(drivers.map((e) => ({ label: toCapitalize(e.name), value: e.uid })));
      const dataServices = response.data.map((e) => {
        const driver = drivers.find((item) => item.uid === e.driverId) || null;

        return {
          ...e,
          driver,
          driverName: driver ? driver.name : '-',
        }
      });
      setData(dataServices);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTableColumns([{
      ...tableColumns[0],
      columns: [
        ...tableColumns[0].columns.filter((e) => e.Header !== 'Conductor'),
        {
          Header: 'Conductor',
          Filter: 'selectSearch',
          accessor: "driverId",
          filterLabel: 'Conductor',
          filterOptions: driversOptions,
          Cell: ({ row }) => (
            <Grid container>
              <Tooltip
                placement="top"
                title="Detalle del Conductor"
                aria-label="detail"
              >
                <IconButton
                  disabled={!row.original.driver}
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={() => setModalDetail({ isOpen: true, data: row.original.driver })}
                >
                  <AssignmentIndIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ),
        }
      ],
    }]);
  }, [driversOptions])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lista de Conductores</title>
      </Helmet>
      <Paper>
        <Table
          checkbox={false}
          columns={tableColumns}
          data={data}
          loading={loading || loadingDrivers}
        />
      </Paper>

      <Dialog
        open={modalDetail.isOpen}
        width="600px"
        component={(
          <ModalDetail
            data={modalDetail.data}
            onClose={() => setModalDetail({ isOpen: false })}
          />
        )}
        onClose={() => setModalDetail({ isOpen: false })}
      />
    </>
  );
};

export default NavigationSection({
  title: "Carreras",
  pages: [],
})(Services);
