/* eslint-disable no-case-declarations */
import { SET_NAVEGATION, REMOVE_NAVEGATION, SET_A_PAGE_WITH_INDEX } from "./actions";

function NavegationLevelReducer(state, action) {
  switch (action.type) {
    case SET_NAVEGATION:
      return {
        ...state,
        ...action.payload 
      };
    case SET_A_PAGE_WITH_INDEX:
      const { index, page, title } = action.payload;
      const nextPages = state.pages;
      if (index && page) {
        nextPages[index] = page;
      }
      return {
        title,
        pages: [
          ...nextPages
        ],
      };
    case REMOVE_NAVEGATION:
      return {
        title: "",
        pages: [
          {
            path: "",
            title: "",
          },
          {
            title: "",
          },
        ],
      };
    default:
      throw new Error();
  }
}

export default NavegationLevelReducer;
