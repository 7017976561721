/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { InputAdornment, FilledInput, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useAsyncDebounce } from "react-table";
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { FormControl } from "./styles";

const TableSearch = ({
  globalFilterValue,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState('');
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  useEffect(() => {
    if(!globalFilterValue) setValue('');
  }, [globalFilterValue])

  return (
    <FormControl>
      <Search />
      <TextField
        id="search-input"
        color="white"
        variant="filled"
        label="Buscar por nombre"
        value={value}
        autoComplete="off"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </FormControl>
  );
};

export default TableSearch;
