/* eslint-disable no-unused-vars */
import styled from "styled-components";
import {
  Grid,
  TableCell as TemplateTableCell,
  TableRow as TemplateTableRow,
} from "@material-ui/core";
import css from "@styled-system/css";
import { variant } from "styled-system";

export const TableTop = styled(Grid)`
  ${(props) =>
    css({
      padding: ["16px 1em"],
      background: props.theme.colors.gray300,
    })}
`;

export const Pagination = styled(Grid)`
  ${(props) =>
    css({
      padding: ["16px 1em"],
      p: {
        fontSize: ["12px"],
        color: props.theme.colors.gray600,
      },
    })}
`;

export const TableRow = styled(TemplateTableRow)`
  ${(props) =>
    variant({
      variants: {
        selectedOne: {
          "&:hover": {
            cursor: "pointer",
            td: {
              background: `${props.theme.colors.tableSelectHover}`,
            },
          },
        },
      },
    })}

  ${(props) =>
    css({
      "&.selected": {
        td: {
          color: `${props.theme.colors.tableSelectColor}`,
          background: `${props.theme.colors.tableSelectActive}`,
        },
      },
    })}
`;

export const TableCell = styled(TemplateTableCell)`
  ${(props) => css({
    padding: "10px 0",
    lineHeight: "1.2",
    textAlign: props.align,
    "&.MuiTableCell-paddingCheckbox": {},
  })}
  ${(props) =>
    variant({
      variants: {
        selectedOne: {
          padding: ["0.8em 0"],
        },
      },
    })}
`;

export const ContainerLoading = styled(TemplateTableCell)`
  overflow: hidden;
  position: relative;
  height: 200px;
  div {
    display: flex;
    justify-content: center;
  }
`;

export const Empty = styled(Grid)`
  ${css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "100%",
  })}
`
