import styled, { css } from "styled-components";
import {
  Grid as TemplateGrid,
} from "@material-ui/core";
import { variant } from "styled-system";
import TemplateAvatar from "@material-ui/core/Avatar";

const Grid = styled(TemplateGrid)`
  ${(props) =>
    variant({
      prop: "styles",
      variants: {
        header: {
          padding: ["16px 1em"],
          borderRadius: "4px 4px 0 0",
          background: props.theme.colors.gray300,
        },
        container: {
          padding: "1em 1.5em",
        },
        containerCommit: {
          padding: "2em 1.5em",
          p: {
            textAlign: 'center',
          }
        },
      },
    })}
`;

export const Avatar = styled(TemplateAvatar)`
  ${css({
    height: '150px',
    width: '150px',
    marginRight: "15px",
    cursor: "pointer",
    border: ".5px dashed #585858",
    img: {
      objectFit: 'cover',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png)',
  })}
`;

export default Grid;
