import React from 'react';
import styled from "styled-components";
import css from "@styled-system/css";
import CircularProgress from '@material-ui/core/CircularProgress';

const ContainerLoading = styled.div`
  ${() => css({
    position: "fixed",
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;

const LoadingPage = () => (
  <ContainerLoading>
    <CircularProgress />
  </ContainerLoading>
);

export default LoadingPage;
