import styled from "styled-components";
import {
  FormControl as TemplateFormControl,
} from "@material-ui/core";
import css from "@styled-system/css";

export const FormControl = styled(TemplateFormControl)`
  ${(props) => css({
    display: 'flex',
    alignItems: 'center',
    flexDirection:'row',
    minWidth: ["220px"],
    marginLeft: '10px',
    borderRadius: '50px',
    backgroundColor: 'white',
    paddingLeft: '12px',
    border: `1px solid ${props.theme.colors.gray400}`,
    '.MuiFilledInput-root': {
      borderRadius: '50px',
      border: 'none',
      backgroundColor: 'white',
      '::before': {
        display: 'none',
      },
      '::after': {
        display: 'none',
      },
    },
  })}
`;

export default FormControl;