export default {
  typeService: [
    { label: "Moto Lineal", value: '0' },
    { label: "Moto Car", value: '1' },
    { label: "Auto", value: '2' },
  ],
  documentType: [
    { label: "DNI", value: '01' },
    { label: "CE", value: '03' },
  ],
  statusServices: [
    { label: "Pendiente", value: '0' },
    { label: "Aceptado", value: '1' },
    { label: "Esperando Conductor", value: '2' },
    { label: "En Progreso", value: '3' },
    { label: "Finalizado", value: '4' },
    { label: "Cancelado por Cliente", value: '5' },
    { label: "Expirado", value: '6' },
    { label: "Cancelado por Conductor", value: '7' },
  ],
};
