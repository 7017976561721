/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import {
  useTable, usePagination, useRowSelect, useGlobalFilter, useFilters,
} from "react-table";
import {
  TableContainer, Table, TableHead, TableBody, Grid, Checkbox,
} from "@material-ui/core";
import Filters from './Filters';
import { Pagination, TableCell, TableRow, ContainerLoading, Empty } from "./styles";
import TableTop from "./components/TableTop";
import PerPage from "./components/PerPage";
import ChangePage from "./components/ChangePage";
import TableSearch from "./TableSearch";
import TableColumns from "./components/TableColumns";
import ButtonCleanFilters from "./components/ButtonCleanFilters";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const Template = ({
  columns, data, loading, search, columnFilters, left, right, selectDefault,
  selectedOne, handleClickRow, getAllSelected, checkbox, showTableTop,
  ...rest
}) => {
  const [selectedRowId, setSelectedRowId] = useState(selectDefault);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow,
    page, canPreviousPage, canNextPage, pageOptions, nextPage,
    previousPage, setPageSize, allColumns, setAllFilters,
    setGlobalFilter, selectedFlatRows, state: { pageIndex, pageSize, filters, globalFilter },
  } = useTable(
  {
    columns,
    data,
    initialState: rest || {},
  },
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
    (hooks) => {
      // eslint-disable-next-line no-shadow
      hooks.visibleColumns.push((columns) => [
        !selectedOne && checkbox
          ? // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          }
          : {},
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setSelectedRowId(selectDefault || null);
  }, [data]);

  useEffect(() => {
    const selected = selectedFlatRows.map((row) => row.original);
    getAllSelected(selected);
  }, [selectedFlatRows])

  // Render the UI for your table
  return (
    <>
      {showTableTop && (
        <TableTop
          left={(
            <>
              { search && (
                <TableSearch
                  globalFilterValue={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
              {headerGroups.map((headerGroup) => (
                headerGroup.headers.map((column) => (
                  column.canFilter && column.Filter ? column.render(Filters[column.Filter]) : null
                ))
              ))}
              {(Boolean(filters.length) || globalFilter ) && (
                <ButtonCleanFilters onClick={() => { setAllFilters([]); setGlobalFilter(''); }} />
              )}
              {left}
            </>
          )}
          right={(
            <>
              {columnFilters && <TableColumns allColumns={allColumns} />}
              {right}
            </>
          )}
        />
      )}
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    padding={index === 0 && "checkbox"}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {!loading ? page.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={selectedRowId === row.id ? "selected" : ""}
                  onClick={() => {
                    if (selectedOne) {
                      setSelectedRowId(row.id);
                      handleClickRow(row.original);
                    }
                  }}
                  variant={selectedOne ? "selectedOne" : null}
                >
                  {row.cells.map((cell, index) => (
                    <TableCell
                      variant={selectedOne ? "selectedOne" : null}
                      padding={index === 0 && "checkbox"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            }) : (
              <TableRow>
                <ContainerLoading colspan="100">
                  <div>
                    <CircularProgress color="primary" />
                  </div>
                </ContainerLoading>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && page.length === 0 && <Empty>No hay información a mostrar</Empty>}
      <Pagination item container xs={12} justify="space-between" padding={2}>
        <Grid xs={6} item container direction="row" alignItems="center">
          <PerPage
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          />
        </Grid>
        <Grid xs={6} item container justify="flex-end" alignItems="center">
          <ChangePage
            pageSize={pageSize}
            currentPage={pageIndex + 1}
            totalPages={pageOptions.length}
            canPreviousPage={canPreviousPage}
            totalRows={data.length}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
          />
        </Grid>
      </Pagination>
    </>
  );
};

Template.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.object,
  loading: PropTypes.bool,
  search: PropTypes.bool,
  columnFilters: PropTypes.bool,
  left: PropTypes.element,
  right: PropTypes.element,
  selectedOne: PropTypes.bool,
  handleClickRow: PropTypes.func,
  getAllSelected: PropTypes.func,
  selectDefault: PropTypes.string,
  checkbox: PropTypes.bool,
  showTableTop: PropTypes.bool,
};

Template.defaultProps = {
  data: [],
  columns: {},
  loading: false,
  search: true,
  columnFilters: true,
  left: () => { },
  right: () => { },
  selectedOne: false,
  handleClickRow: () => { },
  getAllSelected: () => { },
  selectDefault: null,
  checkbox: true,
  showTableTop: true,
};

export default Template;
