/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";

export const Address = styled.p`
  ${css({
    cursor: 'default',
    maxWidth: '225px',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  })}
`;

export const Name = styled.p`
  ${css({
    maxWidth: '125px', 
  })}
`;

export const Status = styled.p`
  ${css({
    maxWidth: '100px', 
  })}
`;