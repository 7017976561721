import React from "react";
import Layout from "shared/Layout";
import RouterOutlet from "shared/RouterOutlet";
import { Switch } from "react-router-dom";
import routes from "./routes";

const Admin = () => (
  <Layout>
    <Switch>
      {routes.map((route) => (
        <RouterOutlet key={route.path} {...route} />
      ))}
    </Switch>
  </Layout>
  );

export default Admin;
