/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import Globals from 'utils/globals';
import { Tooltip } from "@material-ui/core";
import { toCapitalize } from "utils/functions";
import { Address, Name, Status } from './styles';

const columns = [
  {
    Header: "",
    id: "name",
    isVisible: false,
    hideHeader: false,
    columns: [
      {
        Header: "Fecha",
        Cell: ({ row }) => {
          const date = moment.unix(row.original.dateRide.seconds).format("DD/MM/YYYY hh:mm a").split(' ');
          return (
            <p>
              {date[0]}
              <br />
              {date[1]}
              {' '}
              {date[2]}
            </p>
          )
        },
      },
      {
        Header: "Cliente",
        accessor: "username",
        Cell: ({row}) => (<Name>{toCapitalize(row.original.username)}</Name>)
      },
      {
        Header: "Origen",
        Cell: ({ row }) => (
          <Tooltip 
            title={(
              <p style={{ fontSize: "12px" }}>
                {row.original.origin.address} 
                {' '}
                <br /> 
                {' '}
                <span style={{ fontSize: "10px", textTransform: "uppercase" }}>
                  {row.original.origin.name}
                </span>
              </p>
            )}
            aria-label="origin"
            placement="top"
          >
            <Address>
              {row.original.origin.address || row.original.origin.name}
            </Address>
          </Tooltip>
        ),
      },
      {
        Header: "Destino",
        Cell: ({ row }) => (
          <Tooltip
            title={(
              <p style={{ fontSize: "12px" }}>
                {row.original.destination.address}
                {' '}
                <br /> 
                {' '}
                <span style={{ fontSize: "10px", textTransform: "uppercase" }}>
                  {row.original.destination.name}
                </span>
              </p>
            )}
            aria-label="destination"
            placement="top"
          >
            <Address>
              {row.original.destination.address || row.original.destination.name}
            </Address>
          </Tooltip>
        ),
      },
      {
        Header: "Estado",
        accessor: "status",
        Filter: 'select',
        filterLabel: 'Estado',
        filterOptions: Globals.statusServices,
        Cell: ({ row }) => {
          const status = Globals.statusServices.find((e) => (e.value === row.original.status));
          return <Status>{status ? status.label : '-'}</Status>;
        },
      },
      {
        Header: "Precio",
        accessor: "price",
        Cell: ({ row }) => (`S/ ${row.original.price}`),
      },
    ],
  },
];

export default columns;
