/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Button, CircularProgress, Divider, FilledInput,
  FormHelperText, InputAdornment, InputLabel, MenuItem, Paper, Select,
} from "@material-ui/core";
import NavigationSection from "shared/NavigationSection";
import { useForm, Controller } from "react-hook-form";
import {
  CreditCardOutlined, MailOutline, PhoneOutlined,
} from "@material-ui/icons";
import { SET_A_PAGE_WITH_INDEX } from "providers/NavegationLevel/actions";
import { NavegationLevelDispatchContext, NavegationLevelContext } from "providers/NavegationLevel/provider";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Text from "components/Text";
import useFetch from "services/useFetch";
import globals from "utils/globals";
import { SnackbarDispatchContext } from "providers/Snackbar/provider";
import { SNACKBAR_SHOW } from "providers/Snackbar/actions";
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LoadingPage from "components/LoadingPage";
import { toCapitalize } from "utils/functions";
import validation from "./validation";
import { Grid, FormControl } from "./styles";

const CreateEdit = ({ history, match }) => {
  const { params: { id } } = match;
  const { title: navTitle, ...navegation } = useContext(NavegationLevelContext);
  const dispatchNavegation = useContext(NavegationLevelDispatchContext);
  const { handleSubmit, control, errors, setValue } = useForm({ resolver: validation });
  const dispatch = useContext(SnackbarDispatchContext);
  const { fetch: submit, loading: loadingSubmit } = useFetch({ uri: "/api/drivers" });
  const { fetch: fetchGetDriver, loading: loadingGetDriver} = useFetch({ uri: `/api/drivers/${id}` });

  const handleGetDriver = async () => {
    const { status, data } = await fetchGetDriver();
    if (status === 200) {
      Object.keys(data).forEach((key) => {
        if (key === 'driverInfo') {
          Object.keys(data[key]).forEach((key2) => {
            setValue(key2, data[key][key2]);
          })
          return;
        }
        setValue(key, data[key]);
      });
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDriver()
    }
  }, []);

  useEffect(() => {
    if ( id && navTitle !== "Editar Conductor") {
      dispatchNavegation({
        type: SET_A_PAGE_WITH_INDEX,
        payload: {
          title: "Editar Conductor",
          index: '1',
          page: {
            title: 'Editar'
          }
        }
      })
    }
  }, [navegation]);

  const onSubmit = async (data) => {
    if (!loadingSubmit) {
      const { email, name, lastname, phone, ...rest } = data;
      const dataToSend = {
        email,
        name: toCapitalize(name),
        phone,
        driverInfo: {
          ...rest,
        },
      };

      if (id) {
        dataToSend.uid = id;
      };

      const response = await submit(dataToSend, {
        method: id ? "put" : "post",
      });

      if (response.status === 201 || response.status === 200) {
        dispatch({
          type: SNACKBAR_SHOW,
          payload: {
            severity: "success",
            message: "Conductor registrado correctamente",
          },
        });
        history.push("/admin/conductores");
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{id ? "Editar Conductor" : "Registrar Conductor"}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Grid container>
            <Grid item xs={12} styles="header">
              <Text>{id ? 'Edición' : 'Registro'}</Text>
            </Grid>
          </Grid>
          <Grid container styles="container" spacing={3}>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="name">Nombres y Apellidos</InputLabel>
                <Controller
                  as={FilledInput}
                  id="name"
                  name="name"
                  defaultValue=""
                  control={control}
                  autoComplete='off'
                  error={errors.name}
                  placeholder="Nombres y Apellidos"
                  startAdornment={(
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  )}
                />
                {errors.name && (
                  <FormHelperText error>{errors.name.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="documentType">Tipo de documento</InputLabel>
                <Controller
                  as={Select}
                  defaultValue=""
                  id="documentType"
                  control={control}
                  name="documentType"
                  autoComplete='off'
                  placeholder="Tipo de documento"
                  error={errors.documentType}
                  startAdornment={(
                    <InputAdornment position="start">
                      <CreditCardOutlined />
                    </InputAdornment>
                  )}
                >
                  {globals.documentType.map((e) => (
                    <MenuItem value={e.value}>{e.label}</MenuItem>
                  ))}
                </Controller>
                {errors.documentType && (
                  <FormHelperText error>
                    {errors.documentType.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="document">
                  Número documento
                </InputLabel>
                <Controller
                  as={FilledInput}
                  control={control}
                  id="document"
                  name="document"
                  autoComplete='off'
                  placeholder="Número de documento"
                  error={errors.document}
                  startAdornment={(
                    <InputAdornment position="start">
                      <CreditCardOutlined />
                    </InputAdornment>
                  )}
                />
                {errors.document && (
                  <FormHelperText error>
                    {errors.document.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Controller
                  as={FilledInput}
                  id="email"
                  name="email"
                  defaultValue=""
                  control={control}
                  autoComplete='off'
                  placeholder="Email"
                  error={errors.email}
                  startAdornment={(
                    <InputAdornment position="start">
                      <MailOutline />
                    </InputAdornment>
                  )}
                />
                {errors.email && (
                  <FormHelperText error>{errors.email.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="phone">Télefono</InputLabel>
                <Controller
                  as={FilledInput}
                  id="phone"
                  type="number"
                  defaultValue=""
                  name="phone"
                  autoComplete='off'
                  control={control}
                  error={errors.phone}
                  placeholder="Teléfono"
                  startAdornment={(
                    <InputAdornment position="start">
                      <PhoneOutlined />
                    </InputAdornment>
                  )}
                />
                {errors.phone && (
                  <FormHelperText error>{errors.phone.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="typeService">Tipo de servicio</InputLabel>
                <Controller
                  as={(
                    <Select>
                      {globals.typeService.map((e) => (
                        <MenuItem key={e.value} value={e.value}>
                          {e.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  defaultValue=""
                  control={control}
                  id="typeService"
                  name="typeService"
                  placeholder="Tipo de servicio"
                  error={errors.typeService}
                  startAdornment={(
                    <InputAdornment position="start">
                      <EmojiTransportationIcon />
                    </InputAdornment>
                  )}
                />
                {errors.typeService && (
                  <FormHelperText error>
                    {errors.typeService.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="plate">Placa</InputLabel>
                <Controller
                  as={FilledInput}
                  id="plate"
                  defaultValue=""
                  name="plate"
                  control={control}
                  autoComplete='off'
                  placeholder="Seriales de placa"
                  error={errors.plate}
                  startAdornment={(
                    <InputAdornment position="start">
                      <CalendarViewDayIcon />
                    </InputAdornment>
                  )}
                />
                {errors.plate && (
                  <FormHelperText error>
                    {errors.plate.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="marc">
                  Marca
                </InputLabel>
                <Controller
                  as={FilledInput}
                  defaultValue=""
                  control={control}
                  autoComplete='off'
                  id="marc"
                  name="marc"
                  placeholder="Marca del vehículo"
                  error={errors.marc}
                  startAdornment={(
                    <InputAdornment position="start">
                      <DriveEtaIcon />
                    </InputAdornment>
                  )}
                />
                {errors.marc && (
                  <FormHelperText error>
                    {errors.marc.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="model">
                  Modelo
                </InputLabel>
                <Controller
                  as={FilledInput}
                  defaultValue=""
                  control={control}
                  autoComplete='off'
                  id="model"
                  name="model"
                  placeholder="Modelo del vehículo"
                  error={errors.model}
                  startAdornment={(
                    <InputAdornment position="start">
                      <DriveEtaIcon />
                    </InputAdornment>
                  )}
                />
                {errors.model && (
                  <FormHelperText error>
                    {errors.model.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="filled">
                <InputLabel htmlFor="fabrishYear">
                  Año de Fabricación
                </InputLabel>
                <Controller
                  as={FilledInput}
                  defaultValue=""
                  control={control}
                  autoComplete='off'
                  id="fabrishYear"
                  name="fabrishYear"
                  placeholder="Año de fabricación del vehículo"
                  error={errors.fabrishYear}
                  startAdornment={(
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  )}
                />
                {errors.fabrishYear && (
                  <FormHelperText error>
                    {errors.fabrishYear.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid container styles="container" justify="flex-end" spacing={2}>
            <Grid item xs={2}>
              <Button size="large" fullWidth onClick={() => history.goBack()}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
              >
                {loadingSubmit ? <CircularProgress size={24} /> : id ? "Guardar" : "Registrar"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>

      {loadingGetDriver && (<LoadingPage />)}
    </>
  );
};

export default NavigationSection({
  title: "Registrar Conductor",
  pages: [
    {
      path: "/admin/conductores",
      title: "Conductores",
    },
    {
      title: "Nuevo",
    },
  ],
})(CreateEdit);
