import React from 'react';
import { LazyLoadImage as LazyImg } from 'react-lazy-load-image-component';

const LazyLoadImage = (imageProps) => {
  const { src, id, ...props } = imageProps;

  return (
    <LazyImg
      {...props}
      effect="blur"
      src={src || `/api/users/image/${id}`}
    />
  );
};

export default LazyLoadImage;