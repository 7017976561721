/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from './styles';

function SelectSearchColumnfilter(props) {
  const [inputValue, setInputValue] = useState('');
  const { column: { filterValue, setFilter, filterOptions, filterLabel, id } } = props

  useEffect(() => {
    if (!filterValue) {
      setInputValue('');
    }
  }, [filterValue]);

  return (
    <FormControl variant="filled">
      <Autocomplete
        disableClearable
        value={filterValue}
        inputValue={inputValue}
        id={`selectSearch-${id}`}
        options={filterOptions}
        style={{width: '250px'}}
        getOptionLabel={(option) => option.label}
        onChange={(e, newValue) => setFilter(newValue.value)}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => <TextField {...params} label={filterLabel} variant="filled" />}
      />
    </FormControl>
  )
};

SelectSearchColumnfilter.propTypes = {
  column: PropTypes.objectOf({
    filterValue: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
    filterOptions: PropTypes.array.isRequired
  }).isRequired,
};

export default SelectSearchColumnfilter;
