/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import useFetch from "services/useFetch";
import Table from "components/Table";
import NavigationSection from "shared/NavigationSection";
import { Helmet } from "react-helmet";
import { IconButton, Paper, Tooltip, Grid } from "@material-ui/core";
import { AddIcon } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import Dialog from "components/Dialog";
import { Create } from "@material-ui/icons";
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LazyImage from "components/LazyImage";
import { toCapitalize } from "utils/functions";
import columns from "./columns";
import { Avatar, Name } from "./styles";
import ModalAddImage from "./components/ModalAddImage";
import ModalHandleState from "./components/ModalState";

const Drivers = () => {
  const { fetch, loading } = useFetch({ uri: "/api/drivers" });
  const [data, setData] = useState([]);
  const [tableColumns, setTableColumns] = useState(columns);
  const [openModalState, setOpenModalState] = useState({ isOpen: false, data: {} });
  const [openModalAddImage, setOpenModalAddImage] = useState({ isOpen: false, data: {} });
  const [reload, setReload] = useState(false);

  const reloadData = () => (setReload(!reload));

  const getData = async () => {
    const response = await fetch({});
    if (response.status === 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, [reload]);

  useEffect(() => {
    if (
      !tableColumns[0].columns.some((e) => e.Header === 'Names')
      && !tableColumns[0].columns.some((e) => e.Header === '')
    ) {
      setTableColumns([{
        ...tableColumns[0],
        columns: [
          {
            Header: "Nombres",
            accessor: "name",
            Cell: ({ row }) => (
              <Grid container alignItems="center">
                <Avatar
                  onClick={() => setOpenModalAddImage({ isOpen: true, data: { id: row.original.uid } })}
                >
                  <LazyImage
                    id={row.original.uid}
                    alt="profile"
                    height="50px"
                    width="50px"
                  />
                </Avatar>
                <Name>{toCapitalize(row.original.name)}</Name>
              </Grid>
            ),
          },
          ...tableColumns[0].columns,
          {
            Header: "Acciones",
            accessor: "status",
            Filter: 'select',
            filterLabel: 'Estado del usuario',
            filterOptions: [
              { value: '0', label: 'Por Aprobar' },
              { value: '1', label: 'Activos' },
              { value: '2', label: 'Bloqueados' },
            ],
            Cell: ({ row }) => (
              <Grid container>
                <Tooltip
                  title={
                    row.original.status === 0
                      ? 'Aprobar solicitud' :
                      row.original.status === 1
                        ? 'Bloquear usuario' :
                        'Activar usuario'
                  }
                  aria-label="edit"
                >
                  <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenModalState({ isOpen: true, data: row.original })}
                  >
                    {row.original.status === 0 && (
                      <OfflinePinIcon fontSize="small" />
                    )}
                    {row.original.status === 1 && (
                      <CancelIcon fontSize="small" />
                    )}
                    {row.original.status === 2 && (
                      <CheckCircleIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
                <Link to={`/admin/conductores/editar/${row.original.uid}`}>
                  <Tooltip title="Editar" aria-label="edit">
                    <IconButton
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                    >
                      <Create fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Grid>
            ),
          },
        ]
      }]);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lista de Conductores</title>
      </Helmet>
      <Paper>
        <Table
          checkbox={false}
          columns={tableColumns}
          data={data}
          loading={loading}
          right={(
            <Tooltip title="Agregar" aria-label="add">
              <Link to="/admin/conductores/registrar">
                <IconButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        />
      </Paper>

      <Dialog
        width="600px"
        open={openModalAddImage.isOpen}
        component={(
          <ModalAddImage
            reload={reloadData}
            data={openModalAddImage.data}
            onClose={() => setOpenModalAddImage({ isOpen: false, data: {} })}
          />
        )}
        onClose={() => setOpenModalAddImage({ isOpen: false, data: {} })}
      />

      <Dialog
        open={openModalState.isOpen}
        width="450px"
        component={(
          <ModalHandleState
            reload={reloadData}
            data={openModalState.data}
            onClose={() => setOpenModalState({ isOpen: false })}
          />
        )}
        onClose={() => setOpenModalState({ isOpen: false })}
      />
    </>
  );
};

export default NavigationSection({
  title: "Conductores",
  pages: [],
})(Drivers);
