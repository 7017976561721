/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/named */
import * as yup from "yup";
import useYupValidationResolver, { setLocaleYup } from "utils/yup";

setLocaleYup();

const validationSchema = yup.object({
  email: yup.string().email('Correo no válido').required(),
  password: yup.string().required(),
});

const validation = useYupValidationResolver(validationSchema);

export default validation;
