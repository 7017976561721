import styled, { css } from "styled-components";
import TemplateAvatar from "@material-ui/core/Avatar";

export default styled.div`
  ${(props) =>
    css({
      width: [
        `calc(100% - ${
          props.sideBarStatic ? "280px" : "70px"
        })`,
      ],
      background: "white",
      position: "fixed",
      height: "64px",
      top: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0 2px 4px rgb(0 0 0 / 8%)",
      p: {
        paddingLeft: ["2em"],
      },
      button: {
        paddingRight: ["2em"],
      },
      zIndex: 10,
    })}
`;
export const Avatar = styled(TemplateAvatar)`
  ${(props) =>
    css({
      background: `${props.theme.colors.primary}4a`,
      svg: {
        fill: props.theme.colors.primary,
      },
    })}
`;
