/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useContext } from "react";
import {
  Button, CircularProgress, Divider, Paper,
} from "@material-ui/core";
import Text from "components/Text";
import useFetch from "services/useFetch";
import { SnackbarDispatchContext } from "providers/Snackbar/provider";
import { SNACKBAR_SHOW } from "providers/Snackbar/actions";
import Grid from "./styles";

const ModalState = ({ onClose, data: dataPayment = {}, reload }) => {
  const { uid, status, name } = dataPayment;
  const dispatch = useContext(SnackbarDispatchContext);
  const { fetch: fetchState, loading: loadingFetchState } = useFetch({ uri: `/api/drivers/status` });

  const handleState = async () => {
    if (!loadingFetchState) {
      const config = {
        method: "put",
      };

      const dataToSend = { uid };

      if (status === 0) {
        dataToSend.status = 1;
      } else if (status === 1) {
        dataToSend.status = 2;
      } else if (status === 2) {
        dataToSend.status = 1;
      }

      const response = await fetchState(dataToSend, config);

      if (response.status === 201 || response.status === 200 || response.status === 204) {
        reload();
        dispatch({
          type: SNACKBAR_SHOW,
          payload: {
            severity: "success",
            message: `Estado del usuario actualizado con éxito`,
          },
        });
        onClose();
      }
    }
  };

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} styles="header">
          <Text variant="h3">Notificación</Text>
        </Grid>
      </Grid>
      <Grid container styles="containerCommit" spacing={2} justify="center">
        <p>
          ¿Desea 
          {' '}
          {
            status === 0
              ? 'aprobar la solicitud del usuario' :
              status === 1
                ? 'bloquear al usuario' :
                'activar al usuario'
          }
          {' '}
          <strong>{name}</strong>
          ?
        </p>
      </Grid>
      <Divider />
      <Grid container styles="container" justify="flex-end" spacing={2}>
        <Grid item xs={3}>
          <Button size="large" fullWidth onClick={() => onClose()}>
            No
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            size="large"
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleState}
          >
            {loadingFetchState ? <CircularProgress size={24} /> : "Si"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ModalState;
