/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";
import TemplateAvatar from "@material-ui/core/Avatar";

export const Avatar = styled(TemplateAvatar)`
  ${css({
    height: '35px',
    width: '35px',
    marginRight: "15px",
    cursor: "auto",
    img: {
      objectFit: 'cover',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png)',
  })}
`;

export const Name = styled.p`
  width: 140px;
  line-height: 1.25;
`;

export const Point = styled.div`
  ${(props) => css({
    width: '13px',
    height: '13px',
    borderRadius: '100%',
    backgroundColor: props.color,
    position: 'relative',
    '::before': {
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      width: '13px',
      height: '13px',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: `1px solid ${props.color}`,
      content: '""',
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(1.7)',
        opacity: 0,
      },
    },
  })}
`;
