import React from "react";
import {
  IconButton,
  Tooltip,
} from "@material-ui/core";
import PropTypes from 'prop-types'
import ClearIcon from '@material-ui/icons/Clear';

const ButtonCleanFilters = (props) => (
  <Tooltip title="Limpiar filtros" aria-label="filters">
    <IconButton
      style={{'margin-left': '10px'}}
      aria-controls="customized-menu"
      aria-haspopup="true"
      variant="contained"
      color="primary"
      {...props}
    >
      <ClearIcon />
    </IconButton>
  </Tooltip>
);

ButtonCleanFilters.propTypes = {
  props: PropTypes.object.isRequired,
};

export default ButtonCleanFilters;
