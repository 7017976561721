import React, { useContext } from "react";
import Nav, { NavItem } from "components/Nav";
import { withRouter } from "react-router-dom";
import { ConfigContext, ConfigDispatchContext } from "providers/Config/provider";
import Button from "components/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { CONFIG_CHANGE_STATIC_SIDEBAR } from "providers/Config/actions";
import { withTheme } from "styled-components";
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import PeopleIcon from '@material-ui/icons/People';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import { NavLogo, StaticSideBar, Text } from "./styles";

const Sidebar = () => {
  const config = useContext(ConfigContext);
  const dispatch = useContext(ConfigDispatchContext);

  return (
    <Nav variant="fixed" sideBarStatic={config.sideBarStatic}>
      <NavLogo className="d-flex align-items-center">
        <img src="/images/logoWill.png" alt="Logo" />
        <Text variant="h1">Administrador</Text>
        <StaticSideBar sideBarStatic={config.sideBarStatic}>
          <Button
            className="btn-static-sidebar"
            onClick={() => {
              dispatch({ type: CONFIG_CHANGE_STATIC_SIDEBAR });
            }}
          >
            <DoubleArrowIcon fontSize="small" fill="white" />
          </Button>
        </StaticSideBar>
      </NavLogo>
      <NavItem to="/admin/conductores" activeClassName="active" exact={false}>
        <LocalTaxiIcon />
        Conductores
      </NavItem>
      <NavItem to="/admin/clientes" activeClassName="active" exact={false}>
        <PeopleIcon />
        Usuarios
      </NavItem>
      <NavItem to="/admin/carreras" activeClassName="active" exact={false}>
        <EmojiTransportationIcon />
        Carreras
      </NavItem>
    </Nav>
  );
};

export default withRouter(withTheme(Sidebar));
