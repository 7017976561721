import styled, { css } from "styled-components";
import {
  FormControl as TemplateFormControl,
} from "@material-ui/core";

export const FormControl = styled(TemplateFormControl)`
  ${(props) => css({
    minWidth: ["180px"],
    marginLeft: '10px',
    borderRadius: '50px',
    paddingLeft: '10px',
    backgroundColor: 'white',
    border: `1px solid ${props.theme.colors.gray400}`,
    '.MuiFilledInput-root': {
      borderRadius: '50px',
      border: 'none',
      backgroundColor: 'white',
      '::before': {
        display: 'none',
      },
      '::after': {
        display: 'none',
      }
    },
    '.MuiSelect-select:focus': {
        background: 'transparent',
        borderRadius: '50px',
    },
    '.MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(23px, 10px) scale(0.75)',
    },
    '.MuiInputLabel-filled ': {
      transform: 'translate(25px, 20px) scale(1)',
    }
  })}
`;

export default FormControl;
