/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React from "react";
import {
  Button, Divider, Paper,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Text from "components/Text";
import LazyImage from "components/LazyImage";
import Globals from "utils/globals";
import Grid, { Avatar } from "./styles";

const ModalDetail = ({ onClose, data }) => {

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} styles="header">
          <Text variant="h3">Detalle del Conductor</Text>
        </Grid>
      </Grid>
      <Grid container styles="containerCommit">
        {data && data.uid && (
        <>
          <Grid container item xs={4} justify="center">
            <Avatar src={`/api/users/image/${data.uid}`}>
              <LazyImage
                id={data.id}
                alt="profile"
                width="200"
                height="200"
              />
            </Avatar>
          </Grid>
          <Grid container item xs={8} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                value={data.name}
                label="Nombre"
                InputProps={{
                readOnly: true,
              }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                value={data.email}
                label="Email"
                InputProps={{
                readOnly: true,
              }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                value={data.phone}
                label="Celular"
                InputProps={{
                readOnly: true,
              }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                value={Globals.typeService.find((e) => e.value == data.driverInfo.typeService).label}
                label="Tipo de Servicio"
                InputProps={{
                readOnly: true,
              }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                value={data.driverInfo.plate}
                label="Placa"
                InputProps={{
                readOnly: true,
              }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </>
        )}
      </Grid>
      <Divider />
      <Grid container styles="container" justify="flex-end" spacing={2}>
        <Grid item xs={3}>
          <Button size="large" fullWidth onClick={onClose}>
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
};

export default ModalDetail;
