/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Select } from "@material-ui/core";
import FormControl from './styles';

function SelectColumnfilter(props) {
  const { column: { filterValue, setFilter, filterOptions, filterLabel, id } } = props

  return (
    <FormControl variant="filled">
      <InputLabel id={`label-${id}`}>{filterLabel}</InputLabel>
      <Select
        labelId={`label-${id}`}
        id={`select-${id}`}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={filterLabel}
      >
        {filterOptions.map((option, i) => (
          <MenuItem key={`option-${i + 1}-${id}`} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};

SelectColumnfilter.propTypes = {
  column: PropTypes.objectOf({
    filterValue: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
    filterOptions: PropTypes.array.isRequired
  }).isRequired,
};

export default SelectColumnfilter;
